<template>
  <div class="analysis">
    analysis
  </div>
</template>

<script>
export default {
  name: 'analysis'
}
</script>

<style lang="scss" scoped>
</style>